import React from "react";
import "../../App.css";
import "./Projects.css";

export default function Projects() {
  return (
    <>
      <h1 className="projects">Projects</h1>
      <div className="project-container">
        <h2 className="project-view">
          Feel free to browse and review the projects I've completed
        </h2>
        <div className="project-files">
          <div>
            <a
              className="text-links"
              href="https://markel-bradford.github.io/baby-shower-website-template"
              target="_blank" rel="noreferrer">
              <h3 className="project-name">
                Baby Shower Website Template: ReactJS, JavaScript, HTML5, CSS
              </h3>
            </a>
            <div className="thumbnail-wrapper">
              <a
                className="text-links"
                href="https://markel-bradford.github.io/baby-shower-website-template"
                target="_blank" rel="noreferrer">
                <img
                  className="thumbnail"
                  src="/images/babyshowertemp.JPG"
                  alt="Baby shower website template"
                />
              </a>
            </div>
            <p className="information">
              This was a passion project that I created using ReactJS in
              preparation of the birth of my son. I am very excited to meet him
              this year. I decided to remove the personal information to create
              a template that can be customized to fit the needs of the user.
              The website includes card components that link to each page, a
              page for the location, a video gallery to share the pregancy
              journey, and links to an RSVP for and registry.
            </p>
            <a
              className="text-links"
              href="https://markel-bradford.github.io/survey-form/"
              target="_blank" rel="noreferrer">
              <h3 className="project-name">Survey Form: HTML5, CSS</h3>
            </a>
            <div className="thumbnail-wrapper">
              <a
                href="https://markel-bradford.github.io/survey-form/"
                target="_blank" rel="noreferrer">
                <img
                  className="thumbnail"
                  src="/images/SurveyForm.png"
                  alt="survery form"
                />
              </a>
            </div>
            <p className="information">
              My task for this project was to build a functional survey form
              using HTML and a CSS style sheet. This was a FreeCodeCamp project,
              and I decided to go with a survey that targeted FreeCodeCamp users
              to collect feedback on their desired roles as a developer, their
              skills, and their experience. Entries can be entered into all
              fields on the form, and it can be submitted once completed.
            </p>
            <a
              className="text-links"
              href="https://markel-bradford.github.io/tribute-page/"
              target="_blank" rel="noreferrer">
              <h3 className="project-name">Tribute Page: HTML5, CSS</h3>
            </a>
            <div className="thumbnail-wrapper">
              <a
                href="https://markel-bradford.github.io/tribute-page/"
                target="_blank" rel="noreferrer">
                <img
                  className="thumbnail"
                  src="/images/tribute.png"
                  alt="Masahiro Sakurai Tribute Page"
                />
              </a>
            </div>
            <p className="information">
              The objective for this project was to build a tribute page
              dedicated to someone important to you using HTML and a CSS style
              sheet. The focus was building an informative website with a
              timeline of impactful events that happened in the person’s life
              and areer. I chose Masahiro Sakurai because he developed my
              all-time favorite game, Super Smash Bros. Melee, for the Nintendo
              GameCube.
            </p>
            <a
              className="text-links"
              href="https://markel-bradford.github.io/Portfolio-Website/"
              target="_blank" rel="noreferrer">
              <h3 className="project-name">Portfolio: HTML5, CSS</h3>
            </a>
            <div className="thumbnail-wrapper">
              <a
                href="https://markel-bradford.github.io/Portfolio-Website/"
                target="_blank" rel="noreferrer">
                <img
                  className="thumbnail"
                  src="/images/portfolio.png"
                  alt="My first portfolio"
                />
              </a>
            </div>
            <p className="information">
              This was my original portfolio project made using HTML and CSS.
              This personal project was my first attempt at developing a
              function website to introduce myself to the development market. It
              has a functional navigation bar, links the open into new tabs, and
              responsive design for mobile viewing.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
