import React from 'react';
import '../App.css';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src='/videos/video_2160.mp4' playsInline autoPlay loop muted />
        <h1 className='welcome'>Welcome to My Portfolio</h1>
        <h2 className='my-name'>Markel Bradford</h2>
    </div>
  );
}

export default HeroSection;

