import React from 'react'
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';


function Homepage() {
  return (
    <div>
      <>
        <HeroSection />
        <Cards />
      </>
    </div>
  )
}



export default Homepage;
